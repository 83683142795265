import React, { Component } from "react";
import { 
    MantineProvider, 
    Center, 
    TextInput, 
    Box, 
    Button, 
    Group,
    Space } from '@mantine/core';
import '@mantine/core/styles.css';
import fetchAuth from "../fetchAuth"
import { IconPencil, IconUserPlus } from '@tabler/icons-react';
import { useForm } from '@mantine/form';
import { useNavigate } from "react-router-dom";


export default function UserNew() {
    const navigate = useNavigate();
    const form = useForm({
        initialValues: {
            username: '',
            password: Math.random().toString(36).slice(-8),
            description: '',
        },

        validate: {
            username: (value) => (value.length > 3 ? null : 'Короткое имя'),
            password: (value) => (value.length > 6 ? null : 'Слабый пароль'),
        },
    });
    const createNewUser = (values) => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(values)
        };
        fetchAuth("/auth/users/new", requestOptions)
            .then(response => {
                if (response.ok) {
                    navigate("/users");
                } else {
                    console.log(response);
                }
            })
    }

    return (
        <MantineProvider defaultColorScheme="dark">
        <Center>
            <Box maw={340} mx="auto">
            <form onSubmit={form.onSubmit(createNewUser)}>
                <TextInput
                    withAsterisk
                    label="username"
                    placeholder="имя"
                    {...form.getInputProps('username')}
                />
                <TextInput
                    withAsterisk
                    label="password"
                    placeholder="пароль"
                    {...form.getInputProps('password')}
                />
                <TextInput
                    label="description"
                    placeholder="Описание"
                    {...form.getInputProps('description')}
                />
                <Group justify="flex-end" mt="md">
                    <Button type="submit"> Добавить</Button>
                </Group>
            </form>
            </Box>
        </Center>
        </MantineProvider>
    );

}

