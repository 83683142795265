import React from 'react';
import Login from './components/Login/Login';
import Registration from './components/Login/Registration';
import useToken, {cleanToken} from './components/Login/useToken';
import Home from "./Home"
import Users from "./components/Users"
import UserNew from "./components/UserNew"


import {
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
  Navigate,
  Outlet,
} from "react-router-dom";


export default function App() {

  const { token, setToken } = useToken();

  return (
    <Routes>
    <Route>
      <Route path="/" element={
        <RequireAuth>
          <Home cleanToken={cleanToken} />
        </RequireAuth>
      } />
      <Route path="/registration" element={
        <RequireAuth>
        <RequireAdmin>
          <Registration />
        </RequireAdmin>
        </RequireAuth>
      } />
      <Route path="/users" element={
        <RequireAuth>
        <RequireAdmin>
          <Users />
        </RequireAdmin>
        </RequireAuth>
      } />
      <Route path="/users/new" element={
        <RequireAuth>
        <RequireAdmin>
          <UserNew />
        </RequireAdmin>
        </RequireAuth>
      } />
      <Route path="/login" element={<Login setToken={setToken} />} />
    </Route>
    </Routes>
  )

}

function RequireAuth({ children }) {
  const { token, /* ... */ } = useToken();
  const location = useLocation();

  if (!token) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

function RequireAdmin({ children }) {
  const user = localStorage.getItem('user');
  if (user == "admin") {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return children;
  }

  return <Navigate to="/login" replace />;
}