import React, { memo } from 'react';
import { Position } from 'reactflow';
import CustomHandle from './CustomHandle';
import { Handle } from 'reactflow';


const CustomNode = (props) => {
    return (
        <>
            { props.id != "0" &&
            <CustomHandle
            isConnectable={1}
            />
            }
            <Handle
                isConnectable={props.id != "0"}
                type='source'
                position='right'
                style={props.id === "0" ? { background: '#fff' } : {}}
            />
        </>
    );
};

export default memo(CustomNode);