import { TextInput, NumberInput, NativeSelect, rem } from '@mantine/core';
import { useEffect, useState, useRef } from 'react';

export function CustomTextInput({value, setValue}) {
  let [lvalue, setStateValue] = useState(value);
  let [error, setError] = useState("");
  const handleChange = (event) => {
    let rawV = event.target.value.toString();
    setStateValue(rawV);
    if (rawV != "" && !rawV.match(/^[0-9a-zA-ZЁёА-я\.\\(\\)\\-]+$/)) {
      setError("Недопустимые символы");
      return;
    } else {
      setValue(rawV);
      setError("");
    }  
  }
  useEffect(() => {
    setStateValue(value);
  }, [value]);

  return (
    <>
    <TextInput 
      size="xs"
      styles={{
        input: { width: rem(50), padding: rem(2)}
      }}
      value={lvalue} 
      error={error}
      onChange={handleChange} />
    </>
  );
}


export function CustomNumberInput(
    {value, error, setValue, allowDecimal, emptyIsZerro, min, max}) {
  let [stateValue, setStateValue] = useState(value);
  let [stateError, setStateError] = useState(error);
  const handleChange = (inputVal) => {
    setStateValue(inputVal);
    if (inputVal === "") {
      if (emptyIsZerro) {
        inputVal = 0;
      } else {
        setStateError("пустое значение");
        return;
      }
    } else {
      setStateError("");
    }
    setValue(inputVal);
  }

  useEffect(() => {
    setStateValue(value);
  }, [value]);
  useEffect(() => {
    setStateError(error);
  }, [error]);

  return (
    <>
    <NumberInput 
      allowDecimal={allowDecimal}
      allowNegative={false}
      hideControls={true}
      allowedDecimalSeparators={['.', ',']}
      size="xs"
      styles={{
        input: { width: rem(50), padding: rem(4)}
      }}
      min={min}
      max={max}
      value={stateValue} 
      error={stateError}
      onChange={handleChange} />
    </>
  );
}


export function SelectSDRInput({value, setValue}) {
  let [lvalue, setStateValue] = useState(value);
  const handleChange = (event) => {
    let rawV = event.target.value.toString();
    setValue(rawV);
    setStateValue(rawV);
  }

  useEffect(() => {
    setStateValue(value);
  }, [value]);
  
  return (
    <NativeSelect 
      value={lvalue} 
      size="xs"
      onChange={handleChange}
      // styles={{input: { width: rem(90), paddingLeft: rem(5)}}}
      data={[
        { value: '', label: 'NO SDR' },
        { value: 'SDR 41' },
        { value: 'SDR 33' },
        { value: 'SDR 26' },
        { value: 'SDR 21' },
        { value: 'SDR 17.6' },
        { value: 'SDR 17' },
        { value: 'SDR 13.6' },
        { value: 'SDR 11' },
        { value: 'SDR 9' },
        { value: 'SDR 7.4' },
      ]}
    />

  );
}
