import { Table, ScrollArea } from '@mantine/core';
import { useEffect, useState } from 'react';
import fetchAuth from "../fetchAuth"


export default function Handbook() {
  const [handbooks, setHandbooks] = useState([]);
  useEffect(() => {
    fetchAuth('/handbook').then((response) => {
      return response.json();
    }).then((jsonData) => {
      setHandbooks(jsonData);
    });
  }, []);

  const rows = handbooks.map((e) => (
    <Table.Tr key={e.number}>
      <Table.Td>{e.sdr}</Table.Td>
      <Table.Td>{e.pressure}</Table.Td>
      <Table.Td>{e.dn}</Table.Td>
      <Table.Td>{e.thickness}</Table.Td>
      <Table.Td>{e.variation}</Table.Td>
    </Table.Tr>
  ));
  return (
    <ScrollArea h="200">
      <Table striped highlightOnHover withTableBorder withColumnBorders>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>SDR</Table.Th>
            <Table.Th>ДАВЛЕНИЕ</Table.Th>
            <Table.Th>DN</Table.Th>
            <Table.Th>ТОЛЩИНА</Table.Th>
            <Table.Th>ИЗМЕНЕНИЕ</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
      </Table>
    </ScrollArea>
  );
}