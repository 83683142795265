import { Group, Button, Grid, rem} from '@mantine/core';
import { useEffect, useState, useRef, useCallback } from 'react';
import Hydrolines from '../entity/Hydrolines'
import HydroSystemScheme from './HydroSystemScheme/HydroSystemScheme'
import HydroTable from './Table/HydroTable'
import fetchAuth from "../fetchAuth"
import Handbook from "./Handbook"
import ImportExportCsv from './ImportExportCsv';
import ExportXlsx from './ExportXlsx'


export default function HydroCalcHome() {
  const [hydrolines, setHydrolines] = useState(new Hydrolines());
  const hsRef = useRef();

  useEffect(() => {
    fetchAuth('calculation/get').then((response) => {
      return response.json();
    }).then((jsonData) => {
      setHydrolines(Hydrolines.fromData(jsonData));
    });
  }, []);

  useEffect(() => {
    hsRef.current = hydrolines;
  });

  const recalculateHydrosystem = () => {
    hydrolines.request('calculation/calc').then((hs) => {
      setHydrolines(hs);
    });
  };

  const addNode = useCallback((sourceLineId, position) => {
    let sourceLine = hsRef.current.getByIndex(sourceLineId);
    let targetLines = sourceLine.dependedParts.map(
      (e) => hsRef.current.getByIndex(e-1)
    );
    let sourceLineHasMain = targetLines.some((e) => e.isMainMagistralPart);
    let isMain = !sourceLineHasMain && sourceLine.isMainMagistralPart;
    let newLines = hsRef.current.withNewItem(sourceLine, position, isMain);
    sourceLine.dependedParts.push(hsRef.current.length());
    setHydrolines(newLines);
  }, [hsRef]);
  
  const updateNodePos = useCallback((nodeId, newPose) => {
    let h = hsRef.current.getByIndex(nodeId);
    h.positionX = newPose.x;
    h.positionY = newPose.y;
    setHydrolines(hsRef.current.clone());
  }, [hsRef]);

  const deleteNode = useCallback((nodeIdx) => {
    setHydrolines(hsRef.current.withDeletedItem(nodeIdx));
  }, [hsRef]);
  
  const insertNode = useCallback((nodeIdx) => {
    setHydrolines(hsRef.current.withInsertedItem(nodeIdx));
  }, [hsRef]);

  return (
    <Grid>
      <Grid.Col span={8} style={{ minHeight: rem(300) }}>
        { hydrolines.length() > 0 &&
        <HydroSystemScheme 
        hydrolines={hydrolines} 
        addNode={addNode}
        updateNodePos={updateNodePos}
        deleteNode={deleteNode}
        insertNode={insertNode}
        />
        }
      </Grid.Col>

      <Grid.Col span={4}>
        <h4 id="tabelLabel">Справочная таблица</h4>
        <Handbook/>
      </Grid.Col>

      <Grid.Col span={12}>
        <h4 id="tabelLabel">Расчетная таблица</h4>
        <HydroTable 
        hydrolines={hydrolines} 
        update={() => {
          setHydrolines(hydrolines.clone());
        }}
        />
      </Grid.Col>      
      <Grid.Col span={12}>
        <Group>
          <ImportExportCsv 
            hydrolines={hydrolines} 
            updateHydrolines={(hs) => setHydrolines(hs)}/>
          <ExportXlsx hydrolines={hydrolines} />
          <Button variant="filled" color="lime" onClick={recalculateHydrosystem}>Расчет участков</Button>
        </Group>
      </Grid.Col> 
    </Grid>
  );
}