import {cleanToken} from './components/Login/useToken';


function updateOptions(options) {
    const update = { ...options };
    if (localStorage.jwt) {
      update.headers = {
        ...update.headers,
        Authorization: `Bearer ${localStorage.jwt}`,
      };
    }
    return update;
  }
  
export default function fetchAuth(url, options) {
  return fetch(url, updateOptions(options)).then((response) => {
    if (response.status === 401) {
      cleanToken();
    }
    return response;
  });
}
  