import fetchAuth from "../fetchAuth"
import { Button, rem } from '@mantine/core';
import { IconFileTypeXls } from '@tabler/icons-react';


export default function ExportXlsx({ hydrolines }) {
  const saveXlsx = () => {

    const requestOptions = {
      method: 'POST',
      headers: {
          'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'Content-Type': 'application/json'
      },
      body: hydrolines.toJson()
    };

    fetchAuth('calculation/xlsx', requestOptions).then((response) => {
      return response.blob();
    }).then((blobData) => {
      let url = window.URL.createObjectURL(blobData);
      let a = document.createElement('a');
      a.href = url;
      a.download = 'report.xlsx';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
  }
  const icon = <IconFileTypeXls style={{ width: rem(18), height: rem(18) }} stroke={1.5} />;
  return (
    <>
      <Button 
      onClick={saveXlsx}
      variant="filled" 
      color="gray">
        Сохранить {icon}
      </Button>
    </>
  );
}