import fetchAuth from "../fetchAuth"
import { Button, FileButton, rem } from '@mantine/core';
import { IconFileTypeCsv } from '@tabler/icons-react';
import Hydrolines from "../entity/Hydrolines";


export default function ImportExportCsv({ hydrolines, updateHydrolines }) {
  const saveCsv = () => {

    hydrolines.request('calculation/save').then((hs) => {
      
      let csvData = hs.toCsv();
      // Create a CSV file and allow the user to download it
      let blob = new Blob([csvData], { type: 'text/csv' });
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      a.href = url;
      a.download = 'data.csv';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });

  }

  const handleFileChange = (uploadefile) => {
    var reader = new FileReader();
    reader.onload = function(e) {
      var contents = e.target.result;
      let hs = Hydrolines.fromCsv(contents);
      updateHydrolines(hs);
    };
    reader.readAsText(uploadefile);
  }

  const icon = <IconFileTypeCsv style={{ width: rem(18), height: rem(18) }} stroke={1.5} />;

  return (
    <>
      <FileButton
      accept="text/csv"
      onChange={handleFileChange}>
        {(props) => <Button color="gray" {...props}>Загрузить {icon}</Button>}
      </FileButton>
      <Button 
      onClick={saveCsv}
      variant="filled" 
      color="gray">
        Сохранить {icon}
      </Button>
    </>
  );
}