import { Hydroline } from "../../entity/Hydrolines";
import Hydrolines from "../../entity/Hydrolines";


export const newEdge = (
    src: number, 
    target: number, 
    h: Hydroline, 
    deleteNode: (nodeId: number) => void,
    insertNode: (nodeId: number) => void) => {
  return {
    type: 'custom',
    id: `${src}-${target}`,
    source: src.toString(),
    target: target.toString(),
    data: { hydroline: h, deleteNode: deleteNode, insertNode: insertNode},
    animated: true,
  };
};


export const newNode = (id: number, position: Object) => {
  return {
    id: id.toString(),
    type: 'custom',
    data: { label: id.toString() },
    position: position,
  }
}

export default function hydrolines2NodesEdges(
    hydrolines: Hydrolines, 
    deleteNode: (nodeId: number) => void, 
    insertNode: (nodeId: number) => void) {
  let zeroNodePoseX = hydrolines.getByIndex(0).positionX ?? 0;
  let zeroNodePoseY = hydrolines.getByIndex(0).positionY ?? 0;
  zeroNodePoseX = zeroNodePoseX - 150;
  const nodes = [
    newNode(0, {x: zeroNodePoseX, y: zeroNodePoseY}),
    ...(hydrolines.map( (h, idx) => {
      let pos = h.positionX ? {x: h.positionX, y: h.positionY} : { x: 150*idx, y: 0 };
      return newNode(idx+1, pos);
    }))
  ];
  
  const hydroEdgesFromNodes = hydrolines.map( (h, idx) => {
    return h.dependedParts.map((targetId) => {
      let src = idx+1;
      return newEdge(src, targetId, hydrolines.getByIndex(targetId-1), deleteNode, insertNode);
    }) 
  }).flat();

  const edges = [
    newEdge(0, 1, hydrolines.getByIndex(0), deleteNode, insertNode),
    ...hydroEdgesFromNodes
  ];
  return [nodes, edges];
}