import { AppShell, Text, Group, Container, Button, Center, Modal } from '@mantine/core';
// import { MantineLogo } from '@mantinex/mantine-logo';
import { useNavigate } from "react-router-dom";
import { MantineProvider } from '@mantine/core';
import HydroCalcHome from './components/HydroCalcHome';
import ReleaseModal from './components/ReleaseModal'
import '@mantine/core/styles.css';

export default function Home({ cleanToken }) {
  const navigate = useNavigate();
  const logout = () => {
    cleanToken();
    navigate('/login');
  }
  const isAdmin = localStorage.getItem('user') === "admin";

  return (
    <MantineProvider defaultColorScheme="dark" >
    <AppShell
      header={{ height: 30 }}
      padding="md"
    >
      <AppShell.Header>
        <Group justify="flex-end">
          { isAdmin &&
          <Button
            component='a'
            href='/users'
            variant="transparent" 
            color="gray" 
            size="xs" radius="xs" 
            >Пользователи
          </Button>
          }
          <Text size="xs">{ localStorage.getItem("user") }</Text>
          <Button variant="transparent" color="gray" size="xs" radius="xs" onClick={logout}>Выйти</Button>
        </Group>
      </AppShell.Header>
      <AppShell.Main>
        {/* <Container fluid> <FetchData /> </Container> */}
        <Container fluid> <HydroCalcHome /> </Container>
      </AppShell.Main>
      <AppShell.Footer>
        <Center>
          <ReleaseModal/>
        </Center>
      </AppShell.Footer>
    </AppShell>
    </MantineProvider>
  );
}
