import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextInput, Paper, Button, PasswordInput, Stack, Center, Alert } from '@mantine/core';
import { upperFirst } from '@mantine/hooks';
import { MantineProvider } from '@mantine/core';
import { useNavigate } from "react-router-dom";
import { IconInfoCircle } from '@tabler/icons-react';


export default function Registration({ setToken }) {
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    const requestOptions =
    {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ username, password })
    };
    try {
      const response = await fetch('/auth/', requestOptions);
      // Handle successful login
      if (!response.ok) {
        throw new Error("HTTP status " + response.status);
      }
      setToken(await response.json());
      navigate('/');
    } catch (error) {
      // Handle login error
      navigate('/login');
    }
  };

  return(
    <MantineProvider defaultColorScheme="dark" >
    <Center >
    <Paper radius="md" p="md" withBorder >
        
        <form onSubmit={handleLogin}>
            <Stack>
            <TextInput 
                required
                label="Имя" 
                //placeholder="Имя"
                radius="md"
                onChange={e => setUserName(e.target.value)} 
            />
            <PasswordInput 
                required
                label="Пароль" 
                //placeholder="Пароль" 
                radius="md"
                onChange={e => setPassword(e.target.value)} 
            />
            <Button type="submit" radius="xl">{upperFirst("войти")}</Button>
            </Stack>
        </form>
    </Paper>
    </Center>
    </MantineProvider>
  )
};
