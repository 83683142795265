import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactFlow, {
  useNodesState,
  useEdgesState,
  addEdge,
  useReactFlow,
  ReactFlowProvider,
  Controls,
  getConnectedEdges,
  ConnectionLineType,
} from 'reactflow';
import CustomNode from './CustomNode';
import CustomEdge from './CustomEdge';
import ExportImageButtons from './ExportImageButtons'

import hydrolines2NodesEdges, { newEdge, newNode } from './Hydrolines2NodesEdges';


import 'reactflow/dist/style.css';
import './HydroSystemScheme.css';

const nodeTypes = {
  custom: CustomNode,
};
const edgeTypes = {
  custom: CustomEdge,
};


const AddNodeOnEdgeDrop = ({ hydrolines, addNode, deleteNode, insertNode, updateNodePos }) => {
  
  const reactFlowWrapper = useRef(null);
  const connectingNodeId = useRef(null);
  const { screenToFlowPosition } = useReactFlow();
  
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  
  useEffect(() => {
    const [hydroNodes, hydroEdges] = hydrolines2NodesEdges(hydrolines, deleteNode, insertNode);
    setNodes(hydroNodes);
    setEdges(hydroEdges);
  }, [hydrolines]);

  const onConnect = useCallback(
    (params) => {
      // reset the start node on connections
      connectingNodeId.current = null;
      setEdges((eds) => addEdge(params, eds))
    },
    [],
  );

  const onConnectStart = useCallback((_, { nodeId }) => {
    connectingNodeId.current = nodeId;
  }, []);

  const onConnectEnd = useCallback(
    (event) => {
      if (!connectingNodeId.current) return;
      let connectingId = parseInt(connectingNodeId.current);
      const targetIsPane = event.target.classList.contains('react-flow__pane');

      if (targetIsPane && connectingId > 0) {
        const pos = screenToFlowPosition({x: event.clientX, y: event.clientY});
        addNode(connectingId-1, pos);
      }
    },
    [screenToFlowPosition],
  );

  const onNodesDelete = useCallback(
    (deleted) => {
      deleted.array.forEach(element => {
        deleteNode(parseInt(element.id));
      }); 
    },
    [nodes, edges]
  );
  
  const onEdgesDelete = useCallback(
    (edges) => {
      deleteNode(parseInt(edges[0].target))
    }, 
    [nodes, edges]
  )

  const onNodeDragStop = useCallback(
    (event, node, nodes) => {
      updateNodePos(parseInt(node.id) - 1, node.position);
    }, [screenToFlowPosition]
  )

  return (
    <div className="wrapper" ref={reactFlowWrapper}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onConnectStart={onConnectStart}
        onConnectEnd={onConnectEnd}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        onNodesDelete={onNodesDelete}
        onEdgesDelete={onEdgesDelete}
        onNodeDragStop={onNodeDragStop}
        connectionLineType={ConnectionLineType.Straight}
        fitView
        fitViewOptions={{ padding: 2 }}
      >
      <Controls><ExportImageButtons/></Controls>
      </ReactFlow>
    </div>
  );
};

export default function HydroSystemScheme(props) {
  return (
  <ReactFlowProvider>
    <AddNodeOnEdgeDrop {...props} />
  </ReactFlowProvider>
  );
};