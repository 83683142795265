import fetchAuth from "../fetchAuth"
import Papa from 'papaparse';


type Position = {
  x: number,
  y: number,
} 

export class Hydroline {
    label: string = "";
    isMainMagistralPart: boolean = true;
    dependedParts: number[] = [];
    lenght: number = 403;
    sdr: string = "SDR 26";
    diameter: number = 450;
    waterConsuption: number = 252.4;
    speed: number = 0;
    startHeight: number = 67;
    endHeight: number = 70;
    waterPressureLoss: number = 0;
    userPersentForFrictionWaterPressureLooses: number = 0;
    frictionWaterPressureLooses: number = 0;
    totalPressureLooses: number = 0;
    waterPressureOnStartLine: number = 6.5;
    waterPressureOnEndLine: number = 0;
    booster: number = 0;
    userWaterPressureNeeded: number = 0;
    positionX: number = 0;
    positionY: number = 0;

    public static fromData(data: Object, position: Position | undefined): Hydroline {
        let h = new Hydroline();
        h.label = data["label"];
        h.isMainMagistralPart = (typeof data["isMainMagistralPart"] === 'boolean') ?
          data["isMainMagistralPart"] : data["isMainMagistralPart"] === "true";
        h.dependedParts = data["dependedParts"] ? 
          data["dependedParts"].split(",").map((e:string) => parseInt(e)) : [];
        h.lenght = parseInt(data["lenght"]);
        h.sdr = data["sdr"];
        h.diameter = parseInt(data["diameter"]);
        h.waterConsuption = parseFloat(data["waterConsuption"]);
        h.speed = parseFloat(data["speed"]);
        h.startHeight = parseFloat(data["startHeight"]);
        h.endHeight = parseFloat(data["endHeight"]);
        h.waterPressureLoss = parseFloat(data["waterPressureLoss"]);
        h.userPersentForFrictionWaterPressureLooses = parseFloat(data["userPersentForFrictionWaterPressureLooses"]);
        h.frictionWaterPressureLooses = parseFloat(data["frictionWaterPressureLooses"]);
        h.totalPressureLooses = parseFloat(data["totalPressureLooses"]);
        h.waterPressureOnStartLine = parseFloat(data["waterPressureOnStartLine"]);
        h.waterPressureOnEndLine = parseFloat(data["waterPressureOnEndLine"]);
        h.booster = parseFloat(data["booster"]);
        h.userWaterPressureNeeded = parseFloat(data["userWaterPressureNeeded"]);
        h.positionX = ("positionX" in data) ? parseFloat(data["positionX"] as string) : (position?.x ?? (150*data["number"]));
        h.positionY = ("positionY" in data) ? parseFloat(data["positionY"] as string) : (position?.y ?? 0);
        return h;
    }

    public static fromOther(h: Hydroline): Hydroline {
      const newh = new Hydroline();
      newh.lenght = h.lenght;
      newh.sdr = h.sdr;
      newh.diameter = h.diameter;
      newh.waterConsuption = h.waterConsuption;
      newh.speed = h.speed;
      newh.startHeight = h.startHeight;
      newh.endHeight = h.endHeight;
      newh.waterPressureLoss = h.waterPressureLoss;
      newh.userPersentForFrictionWaterPressureLooses = h.userPersentForFrictionWaterPressureLooses;
      newh.frictionWaterPressureLooses = h.frictionWaterPressureLooses;
      newh.totalPressureLooses = h.totalPressureLooses;
      newh.waterPressureOnStartLine = h.waterPressureOnStartLine;
      newh.waterPressureOnEndLine = h.waterPressureOnEndLine;
      newh.booster = h.booster;
      newh.userWaterPressureNeeded = h.userWaterPressureNeeded;
      return newh;
    }

}

export default class Hydrolines {

  hydrolines: Hydroline[] = []
  constructor(hs?: Hydroline[]) {
    this.hydrolines = hs || [];
  }

  map(callback: (e: Hydroline, index: number) => any) {
    return this.hydrolines.map(callback);
  }

  getByIndex(i: number): Hydroline {
    return this.hydrolines[i];
  }

  getSourceOf(hydrolineNo: number): Hydroline | undefined {
    let itemSrc = this.hydrolines.find(h => 
      h.dependedParts.some(e => e === hydrolineNo)
    );
    return itemSrc;
  }

  withNewItem(sourceLine: Hydroline, position: Position, isMainMagistralPart: boolean): Hydrolines {
    const nn = Hydroline.fromOther(sourceLine);
    nn.label = `L${this.length()+1}`;
    nn.positionX = position.x;
    nn.positionY = position.y;
    nn.isMainMagistralPart = isMainMagistralPart;
    this.hydrolines.push(nn);
    return new Hydrolines(this.hydrolines);
  }

  withDeletedItem(hydrolineNo: number): Hydrolines {
    // re-connect 
    let itemToDelete = this.getByIndex(hydrolineNo-1);
    let itemSrc = this.getSourceOf(hydrolineNo);
    if (itemSrc != null) {
      itemSrc.dependedParts = itemSrc.dependedParts.filter(e => e !== hydrolineNo);
      itemSrc.dependedParts = itemSrc.dependedParts.concat(itemToDelete.dependedParts);
    }
    
    // update dependencies
    this.hydrolines.forEach((h) => {
      h.dependedParts = h.dependedParts.map((e) => e > hydrolineNo ? e - 1 : e);
    });

    // remove node
    this.hydrolines.splice(hydrolineNo-1, 1);

    return this.clone();
  }

  withInsertedItem(hydrolineNo: number): Hydrolines {
    // update dependencies
    this.hydrolines.forEach((h) => {
      h.dependedParts = h.dependedParts.map((e) => e > hydrolineNo ? e + 1 : e);
    });    
    // devide cut line /2
    let cutH = this.getByIndex(hydrolineNo-1);
    cutH.lenght = Math.round( cutH.lenght / 2 );
    // clone cut line
    let newH = structuredClone(cutH);
    newH.label = newH.label + ".1";
    // connect new line to cut line
    newH.dependedParts = [hydrolineNo+1];

    // insert new line before cut line
    this.hydrolines.splice(hydrolineNo-1, 0, newH);

    // move segment right if source to cut lines distance less than Npx
    let itemSrc = this.getSourceOf(hydrolineNo);
    let minDistance = 300;
    let actualDistance = cutH.positionX - itemSrc!.positionX;
    if (actualDistance < minDistance) {
      let itemsToMove:number[] = []; 
      const iterateNodes = (nid: number) => {
        itemsToMove.push(nid)
        this.getByIndex(nid-1).dependedParts.forEach((e) => {
          iterateNodes(e);
        })
      };
      iterateNodes(hydrolineNo+1);
      itemsToMove.forEach((e) => {
        let h = this.getByIndex(e-1);
        h.positionX = h.positionX + (minDistance - actualDistance);
      });
    }
    newH.positionX = itemSrc!.positionX + (cutH.positionX - itemSrc!.positionX) / 2;

    return this.clone();
  }
  
  clone(): Hydrolines {
    return new Hydrolines(this.hydrolines);
  }

  static fromData(data: Object[], positions: Position[]) {
    return new Hydrolines(data.map(
      (h, idx) => Hydroline.fromData(h, positions ? positions[idx] : undefined))
    );
  }

  static fromCsv(contents: string): Hydrolines {
    let result = Papa.parse(contents, {header: true});
    var data: Object[] = result.data;
    // some editors add empty line to csv so we should skip it
    if (isNaN(data[data.length-1]["lenght"])) {
      data.splice(-1);
    } 
    return Hydrolines.fromData(data, []);
  }

  toJson(): string {
    return JSON.stringify(this.map((hydroline, idx) => {
      let h = {...hydroline, 
        number: idx+1, 
        dependedParts: hydroline["dependedParts"].join(',')};
      return h;
    }));
  }

  toCsv(): string {
    return Papa.unparse(this.hydrolines);
  }

  length(): number {
    return this.hydrolines.length;
  }

  last(): Hydroline | null {
    if (this.length() == 0) {
      return null;
    }
    return this.hydrolines[this.hydrolines.length - 1];
  }

  async request(url: string) {
    const positions = this.map((e, i) => {return {x: e.positionX, y: e.positionY}});
    const requestOptions = {
      method: 'POST',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
      },
      body: this.toJson()
    }
    return await fetchAuth(url, requestOptions).then((response) => {
      return response.json();
    }).then((jsonData) => {
      return Hydrolines.fromData(jsonData, positions);
    });
  }

}