import { Table } from '@mantine/core';
import HydroRow from './HydroRow'
import { useEffect, useState } from 'react';


export default function HydroTable({ hydrolines, update }) {

    return (
        <Table highlightOnHover withTableBorder withColumnBorders fz="xs" lh="xs">
        <Table.Thead>
            <Table.Tr>
                <Table.Th>#</Table.Th>
                <Table.Th>Метка участка</Table.Th>
                <Table.Th>Длина (метр)</Table.Th>
                <Table.Th>Внешний диаметер / толщина стенки (SDR)</Table.Th>
                <Table.Th>Диаметр (мм)</Table.Th>
                <Table.Th>Расход (л/с)</Table.Th>
                <Table.Th>Скорость (м/с)</Table.Th>
                <Table.Th>Начальная высота (м)</Table.Th>
                <Table.Th>Конечная высота (м)</Table.Th>
                <Table.Th>Потери длины (бар)</Table.Th>
                <Table.Th>Потери добавленные (%)</Table.Th>
                <Table.Th>Потери трения (бар)</Table.Th>
                <Table.Th>Потери общие (бар)</Table.Th>
                <Table.Th>Начальное давление (бар)</Table.Th>
                <Table.Th>Конечное давление (бар)</Table.Th>
                <Table.Th>Бустера давление (бар)</Table.Th>
                <Table.Th>Требуемое давление (бар)</Table.Th>
            </Table.Tr>            
        </Table.Thead>
        <Table.Tbody>
        {hydrolines.map((h, index) => 
            <HydroRow hydroline={h} index={index+1} update={update} />)}
        </Table.Tbody>
        </Table>
    );
}