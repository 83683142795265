import { Table, NativeSelect, Checkbox } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useEffect, useState, useRef } from 'react';
import { 
  CustomTextInput, 
  SelectSDRInput,
  CustomNumberInput,
} from './CustomInputs'
import { Hydroline } from '../../entity/Hydrolines';


export default function HydroRow({hydroline, index, update}) {
  const h = hydroline;
  return (
    <Table.Tr key={index}
    bg={h.isMainMagistralPart ? 'var(--mantine-color-blue-light)' : undefined}
    style=
    {{
        '--bs-table-bg': 
            (h.waterPressureOnEndLine !== 0 && h.userWaterPressureNeeded && h.waterPressureOnEndLine < h.userWaterPressureNeeded) ?
                'red' : ''
    }}>
    <Table.Td>{index}</Table.Td>
    <Table.Td>
        <CustomTextInput 
        value={h.label} 
        setValue={(v) => { h.label = v; update(); }}/>
    </Table.Td>
    <Table.Td>
        <CustomNumberInput 
        allowDecimal={false}
        value={h.lenght}
        setValue={(v) => {h.lenght = v; update();}}/>
    </Table.Td>
    <Table.Td>
        <SelectSDRInput 
        value={h.sdr}
        setValue={(v) => {h.sdr = v; update();}}/>
    </Table.Td>
    <Table.Td>
        <CustomNumberInput 
        allowDecimal={false}
        emptyIsZerro={true}
        value={h.diameter} 
        setValue={(v) => {h.diameter = v; update();}}/>
    </Table.Td>
    <Table.Td>
        <CustomNumberInput 
        allowDecimal={true}
        value={h.waterConsuption} 
        setValue={(v) => {h.waterConsuption = v; update();}}/>
    </Table.Td>
    <Table.Td>
        <CustomNumberInput 
        error={h.speed > 2 ? ">2" : ""}
        allowDecimal={true}
        value={h.speed} 
        setValue={(v) => { h.speed = v; update(); }}/>
    </Table.Td>
    <Table.Td>
      <CustomNumberInput
      allowDecimal={true}
      value={h.startHeight} 
      setValue={(v) => {h.startHeight = v; update();}}/>
    </Table.Td>
    <Table.Td>
      <CustomNumberInput 
      allowDecimal={true}
      value={h.endHeight} 
      setValue={(v) => {h.endHeight = v; update();}}/>
    </Table.Td>
    <Table.Td>
        {h.waterPressureLoss}
    </Table.Td>
    <Table.Td>
      <CustomNumberInput 
      allowDecimal={true}
      min={0}
      max={100}
      value={h.userPersentForFrictionWaterPressureLooses} 
      setValue={(v) => {
        h.userPersentForFrictionWaterPressureLooses = v; 
        update();
      }}/>
    </Table.Td>
    <Table.Td>
        {h.frictionWaterPressureLooses}
    </Table.Td>
    <Table.Td>
        {h.totalPressureLooses}
    </Table.Td>
    <Table.Td>
      <CustomNumberInput 
      allowDecimal={true}
      value={h.waterPressureOnStartLine} 
      setValue={(v) => {
        h.waterPressureOnStartLine = v; 
        update();
      }}/>
    </Table.Td>
    <Table.Td>
        {h.waterPressureOnEndLine}
    </Table.Td>
    <Table.Td>
      <CustomNumberInput 
      allowDecimal={true}
      value={h.booster} 
      setValue={(v) => {
        h.booster = v; 
        update();
      }}/>
    </Table.Td>
    <Table.Td>
      <CustomNumberInput 
      allowDecimal={true}
      value={h.userWaterPressureNeeded} 
      setValue={(v) => {
        h.userWaterPressureNeeded = v; 
        update();
      }}/>
    </Table.Td>
    </Table.Tr>

  );
}