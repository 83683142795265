import React from 'react';
import { useReactFlow, getRectOfNodes, getTransformForBounds, Panel, ControlButton } from 'reactflow';
import { toPng, toJpeg, toSvg } from 'html-to-image';
import { ActionIcon, Button, Combobox, Text, useCombobox } from '@mantine/core';
import { IconJpg, IconPng, IconSvg } from '@tabler/icons-react';


function downloadImage(dataUrl, extension) {
  const a = document.createElement('a');

  a.setAttribute('download', 'hydro-system' + extension);
  a.setAttribute('href', dataUrl);
  a.click();
}

const imageWidth = 1024;
const imageHeight = 768;

function ExportImageButtons() {
  const { getNodes } = useReactFlow();
  const toImage = (toFunc, extension) => {
    // we calculate a transform for the nodes so that all nodes are visible
    // we then overwrite the transform of the `.react-flow__viewport` element
    // with the style option of the html-to-image library
    const nodesBounds = getRectOfNodes(getNodes());
    const transform = getTransformForBounds(nodesBounds, imageWidth, imageHeight, 0.5, 2);

    toFunc(document.querySelector('.react-flow__viewport'), {
      // backgroundColor: '#242424',
      width: imageWidth,
      height: imageHeight,
      style: {
        width: imageWidth,
        height: imageHeight,
        transform: `translate(${transform[0]}px, ${transform[1]}px) scale(${transform[2]})`,
      },
    }).then((url) => downloadImage(url, extension));
  };
  
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  return (
    <>
    <ControlButton onClick={() => toImage(toJpeg, '.jpg')}>
      <ActionIcon variant="white" color="gray" size="xs" radius="xs"><IconJpg stroke={2}/> </ActionIcon>
    </ControlButton>
    <ControlButton onClick={() => toImage(toPng, '.png')}>
      <ActionIcon variant="white" color="gray" size="xs" radius="xs"><IconPng stroke={2}/></ActionIcon>
    </ControlButton>
    <ControlButton onClick={() => toImage(toSvg, '.svg')}>
      <ActionIcon variant="white" color="gray" size="xs" radius="xs"><IconSvg  stroke={2}/></ActionIcon>
    </ControlButton>
      {/* <Button onClick={() => combobox.toggleDropdown()}>Сохранить</Button> */}
    </>
  );
}

export default ExportImageButtons;