import React, { FC } from 'react';
import { 
  Text,   
  ActionIcon,
  Group,
  useMantineTheme, } from '@mantine/core';
import { 
  EdgeProps, 
  getStraightPath,
  EdgeLabelRenderer, 
  BaseEdge } from 'reactflow';
import { IconScissors, IconX } from '@tabler/icons-react';

export default function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  style={},
  selected,
  target,
  data,
}: EdgeProps) {
  const [edgePath, labelX, labelY] = getStraightPath({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });
  const theme = useMantineTheme();

  return (
    <>
      <BaseEdge 
        id={id} 
        path={edgePath} 
        style={{...style,
          stroke: data.hydroline.isMainMagistralPart ? "#006600" : "#eeeeee"
        }}
      />
      <EdgeLabelRenderer>
        <div
          style={{
            position: 'absolute',
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            // background: '#000000',
            fontSize: 6,
            padding: 4,
            //borderRadius: 5,
            // fontSize: 8,
            //fontWeight: 700,
            background: theme.colors.dark[7],
            pointerEvents: 'all',
          }}
          className="nodrag nopan"
        >
          <Text size="xs">{data.hydroline.label}-{data.hydroline.lenght}м</Text>
          <Text size="xs">{data.hydroline.sdr} ø{data.hydroline.diameter}</Text>
          {
            selected &&
            <Group justify="space-between">
            <ActionIcon 
              variant="transparent" 
              size="xs" 
              radius="xs" 
              aria-label="Settings"
              onClick={() => data.insertNode(parseInt(target))}
            >
              <IconScissors style={{ width: '70%', height: '70%' }} stroke={1.5} />
            </ActionIcon>
            <ActionIcon 
              variant="transparent" 
              size="xs" 
              radius="xs" 
              aria-label="Settings"
              onClick={() => data.deleteNode(parseInt(target))}
            >
              <IconX style={{ width: '70%', height: '70%' }} stroke={1.5} />
            </ActionIcon>
            </Group>
          }
        </div>
      </EdgeLabelRenderer>
    </>
  );
};
