import React, { Component } from "react";
import { MantineProvider, Center, Table, ActionIcon } from '@mantine/core';
import '@mantine/core/styles.css';
import fetchAuth from "../fetchAuth"
import { Container } from "reactstrap";
import { IconArrowLeft, IconUserPlus, IconTrash } from '@tabler/icons-react';
import { useNavigate } from "react-router-dom";



export class UsersComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {users: []};
        this.getUsers();
    }
    
    render() {
        const rows = this.state.users.map((e) => (
            <Table.Tr key={e.id}>
                <Table.Td>{e.id}</Table.Td>
                <Table.Td>{e.name}</Table.Td>
                <Table.Td>{e.requests_n}</Table.Td>
                <Table.Td>{e.role ? "user" : "admin"}</Table.Td>
                <Table.Td>{e.desc}</Table.Td>
                <Table.Th>
                <ActionIcon disabled={e.role == 0} variant="subtle" aria-label="Settings" 
                    onClick={() => this.delUsers(e.id)}>
                    <IconTrash />
                </ActionIcon>
                </Table.Th> 
            </Table.Tr>
        ));
        return (
            <MantineProvider defaultColorScheme="dark"><Center>
                <Container>
                <ActionIcon component="a" href="/" variant="outline" aria-label="Settings">
                <IconArrowLeft/>
                </ActionIcon>
                <Table striped highlightOnHover withTableBorder withColumnBorders>
                    <Table.Thead>
                        <Table.Tr>
                            <Table.Th>id</Table.Th>
                            <Table.Th>Имя</Table.Th>
                            <Table.Th>Кол-во расчетов</Table.Th>
                            <Table.Th>Роль</Table.Th>
                            <Table.Th>Описание</Table.Th>
                            <Table.Th></Table.Th>
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>{rows}</Table.Tbody>
                </Table>
                </Container>
            </Center>
            <Center>
            <Container>
            <ActionIcon 
                component="a"
                href="/users/new"
                variant="subtle" aria-label="Settings">
                <IconUserPlus />
            </ActionIcon>
            </Container>
            </Center>
            </MantineProvider>
        );
    }

    async getUsers() {
        const response = await fetchAuth('/auth/users');
        const data = await response.json();
        this.setState({users: data});
    }

    async delUsers(userId) {
        await fetchAuth('/auth/users/delete/' + userId, {method: 'POST'})
            .then(response => {
                console.log(response);
                this.getUsers();
            })
    }

}

function Users(props) {
    let navigate = useNavigate();
    return <UsersComponent {...props} navigate={navigate} />
}

export default Users