import { Button, Modal, Text, List, Title } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import React, { useCallback, useEffect, useRef, useState } from 'react';

export default function ReleaseModal() {
  const [opened, { open, close }] = useDisclosure(false);
  const [version, setVersion] = useState('');

  const onEdgesDelete = useEffect(() => {
    let ver = localStorage.getItem('app-version');
    if (ver !== process.env.REACT_APP_VERSION) {
      open();
    }
    ver = process.env.REACT_APP_VERSION;
    localStorage.setItem('app-version', ver)
    setVersion(ver);
  }, [])

  return (
    <>
    <Modal opened={opened} onClose={close} title="Примечания к обновлению">
      
      <Title order={4}>v1.0.0</Title>
      <List>
        <List.Item> "Разрезание" участков</List.Item>
        <List.Item> Соединение участков при удалении</List.Item>
        <List.Item> Копирование параметров исходящего участка при создании нового</List.Item>
        <List.Item> Экспорт схемы в jpg/png/svg</List.Item>
        <List.Item> Fix: формат колонок при экспорте xlsx</List.Item>
        <List.Item> Fix: расчет диаметра</List.Item>
      </List>

    </Modal>
    <Button onClick={open} variant="transparent" color="blue" size="xs" radius="xs">{process.env.REACT_APP_VERSION}</Button>
    </>
  )
}