import { useState } from 'react';

export default function useToken() {
  const getToken = () => {
    const tokenString = localStorage.getItem('jwt');
    return tokenString
  };

  const [token, setToken] = useState(getToken());

  const saveToken = userToken => {
    localStorage.setItem('jwt', userToken['access_token']);
    localStorage.setItem('user', userToken['username']);
    setToken(userToken.access_token);
  };

  return {
    setToken: saveToken,
    token
  }
}

export function cleanToken() {
  localStorage.removeItem('jwt');
  localStorage.removeItem('user');
}
